import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './blog-card.css';


const BlogCard = ({
    id,
    title,
    fileUrl,
    createdAt,
    lastModified,
    readingTime: ReadingTime
}) => {
    const [post, setPost] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const clipContent = (content, limit) => {
        if (content.length <= limit) {
            return content;
        }
    
        let trimmedContent = content.slice(0, limit + 1);
        const lastSpaceIndex = trimmedContent.lastIndexOf(' ');
    
        if (lastSpaceIndex === -1) {
            return trimmedContent.trim() + "...";
        }
    
        return trimmedContent.slice(0, lastSpaceIndex) + "...";
    };

    const fetchMarkdown = useCallback(async () => {
        const lastModifiedDB = lastModified.toMillis();

        try {
            const cachedPost = localStorage.getItem(`blog_${id}`);
            const cachedLastModified = localStorage.getItem(`lastModified_${id}`);

            if (cachedPost && cachedLastModified && parseInt(cachedLastModified, 10) === lastModifiedDB) {
                setPost(cachedPost);
                setLoading(false);
                return;
            }

            const response = await fetch(fileUrl);
            const text = await response.text();
            localStorage.setItem(`blog_${id}`, text);
            localStorage.setItem(`lastModified_${id}`, lastModifiedDB.toString());
            setPost(text);
        } catch (err) {
            setError(true);
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [fileUrl, id]);

    useEffect(() => {
        if (fileUrl) {
            fetchMarkdown();
        }
    }, [fileUrl, fetchMarkdown]);

    if (loading) return
    if (error) return

    const contentSnippet = clipContent(post, 210);

    let date = createdAt.toDate().toDateString();
    date = date.slice(4, 10) + date.slice(10, 15);
    date = date.slice(0, 6) + "," + date.slice(6, 11);

    return (
        <Link className="card-link" to={{ pathname: `/b/${id}` }} state={{ post: post }}>
            <div className="blog-card">
                <div className="card-content">
                    <div className="card-title">
                        <h4 className="blog-title">{title}</h4>
                        <div className="blog-meta">
                            <span className="blog-date">{date}</span>
                            <span className="blog-reading-time">{ReadingTime} min read</span>
                        </div>
                    </div>
                    <div>
                        <div className="card-description">
                            <div className="card-description-text">{contentSnippet}</div><div className="read-more">Read More</div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default BlogCard;
