import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Login from '../../Pages/Auth/Login';

const ProtectedRoute = () => {
    const [isAuth, setIsAuth] = useState(false);
    useEffect(() => {
        if (localStorage.getItem('auth')) {
            setIsAuth(true);
            <Navigate to="/create" />
        }
    }, []);
    return (
        isAuth ? <Outlet /> : <Login />
    );
}

export default ProtectedRoute;