import { logEvent } from 'firebase/analytics';
import Markdown from 'markdown-to-jsx';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import CustomLoader from '../../Components/CustomLoader/CustomLoader';
import { analytics } from '../../firebase';
import './blogpage.css';
import ReadingProgress from './progressBar';

const Blog = ({ blogs }) => {
    const { id } = useParams();
    const blog = blogs.find(blog => blog.id === id);
    const fileUrl = blog?.fileUrl;
    const [post, setPost] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const target = createRef();

    const fetchMarkdown = useCallback(async () => {
        const lastModifiedDB = blog.lastModified.toMillis();

        try {
            const cachedPost = localStorage.getItem(`blog_${id}`);
            const cachedLastModified = localStorage.getItem(`lastModified_${id}`);

            if (cachedPost && cachedLastModified && parseInt(cachedLastModified, 10) === lastModifiedDB) {
                setPost(cachedPost);
                setLoading(false);
                return;
            }

            const response = await fetch(fileUrl);
            const text = await response.text();
            localStorage.setItem(`blog_${id}`, text);
            localStorage.setItem(`lastModified_${id}`, lastModifiedDB.toString());
            setPost(text);
        } catch (err) {
            setError(true);
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [fileUrl, id, blog]);

    useEffect(() => {
        if (fileUrl) {
            logEvent(analytics, 'blog_viewed', { blog_id: id });
            fetchMarkdown();
        }
    }, [fileUrl, fetchMarkdown]);

    let date = blog?.createdAt.toDate().toDateString();
    if (date) {
        date = date.slice(4, 10) + date.slice(10, 15);
        date = date.slice(0, 6) + "," + date.slice(6, 11);
    }

    if (loading) return <CustomLoader />;
    if (error) {
        return <div>Error loading blog</div>;
    }

    return (
        <div className="blog-container">
            <Helmet>
                <title>{`${blog?.title}`}</title>
                <meta property="og:title" content={`${blog?.title}`} />
                <meta property="og:image" content={"https://metamorph.blog/logo.png"} />
                <meta property="og:url" content={`https://metamorph.blog/blog/${id}`} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <ReadingProgress target={target} />
            <div id="post-container" className="post-container" ref={target}>
                <h1>{`${blog?.title}`}</h1>
                <div className="blog-meta blog-page-meta">
                    <span className="blog-date">{date}</span>
                    <span className="blog-reading-time">{blog?.readingTime} min read</span>
                </div>
                <Markdown className="post">
                    {post}
                </Markdown>
            </div>
        </div>
    );
}

export default Blog;