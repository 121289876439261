import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { auth } from '../../firebase';
import './login.css';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [authUser, setAuthUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
                localStorage.setItem('auth', true);
            }
            else {
                setAuthUser(null);
                localStorage.removeItem('auth');
            }
        });
        return () => {
            listen();
        }
    }, []);

    const handleLogout = () => {
        signOut(auth).then(
            () => {
                navigate('/');
                return toast.success("Logged out successfully", { autoClose: 1000 });
            }).catch((error) => { });
    }

    const handleLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                navigate('/create');
                return toast.success("Access granted!", { autoClose: 1000 });
            }).catch((error) => {
                return toast.error("Good luck, puny human. You'll need it.", { autoClose: 1000 });
            });
    }

    return (
        <div className="form-container">
            <h3 className='text-center'>
                You're straying from the path, mortal. Only The Creator can proceed from here. Go back whence you came.
                <br />Or get ripped to shreds by Cerberus. You have been warned...
            </h3>
            <form onSubmit={handleLogin}>
                <label htmlFor="email">Email</label>
                <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <label htmlFor="password">Password</label>
                <input type="password" name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                {authUser ? <><h3>Logged in</h3><button onClick={handleLogout}>Sign out</button></> : <><button onClick={Login}>Login</button></>}
            </form>
        </div>
    );
}

export default Login;
