import { React } from 'react';
import './footer.css';

const Footer = () => {
    return (
        <div className="footer-container">
            <p className="footer-text">© Dhyey Shah 2024. All rights reserved.</p>
        </div>
    );
}

export default Footer;