import { getAnalytics } from "firebase/analytics";
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyCCe1sSYmek4aAI_M1-SkWv9S-W_vPHiH8",
  authDomain: "mindful-meander.firebaseapp.com",
  projectId: "mindful-meander",
  storageBucket: "mindful-meander.appspot.com",
  messagingSenderId: "889180610541",
  appId: "1:889180610541:web:d6b3589c175c1fd45611e4",
  measurementId: "G-QTW2Q0ECLQ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export { analytics, app, auth, db, storage };
