import React, { useEffect } from 'react';
import './poem-card.css';

const PoemCard = ({ title, poem }) => {

    useEffect(() => {
        const poemCardElements = document.querySelectorAll(".poem-card");
        poemCardElements.forEach((element) => {
            element.style.webkitUserSelect = "none";
            element.style.mozUserSelect = "none";
            element.style.msUserSelect = "none";
            element.style.userSelect = "none";
        });
    }, []);
    return (
        <div className="poem-card-container">
            <div className="poem-card">
                <pre>
                    <div className="poem-card-title">
                        {title}
                        <div className="filled"></div>
                    </div>
                    <div className="poem-card-body">
                        <div className="poem-card-text">
                            {poem}
                        </div>
                    </div>
                </pre>

            </div>
        </div>
    );
}

export default PoemCard;