import React, { useEffect, useRef, useState } from 'react';
import PoemCard from '../../Components/PoemCard/PoemCard';
import './poems.css';

const Poems = ({ poems }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredPoems, setFilteredPoems] = useState(poems || []);
  const poemsListRef = useRef(null);

  const cardArray = filteredPoems.map((content, index) => {
    return <PoemCard key={index} title={content[0]} poem={content[1]} />
  });

  const poemsScrollTop = () => {
    poemsListRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    if (!query) {
      setFilteredPoems(poems);
      return;
    }

    const filtered = poems.filter((poem) =>
      poem[0].toLowerCase().includes(query) || poem[1].toLowerCase().includes(query)
    );

    setFilteredPoems(filtered);
  };

  useEffect(() => {
    document.body.classList.add('poems-bg');

    return () => {
      document.body.classList.remove('poems-bg');
    };
  }, []);

  useEffect(() => {
    setFilteredPoems(poems || []);
  }, [poems]);

  return (
    <div className="poems-page-container">
      <div className="poems-container">
        <div className="search-bar-container" ref={poemsListRef}>
          <input
            type="text"
            placeholder="Search poems"
            value={searchQuery}
            onChange={handleSearch}
            className="search-bar"
          />
        </div>
        <div>
          <div className="card-list">
            {cardArray}
          </div>
        </div>
      </div>
      <div className="top-btn-container">
        <button id="poems-top-scroll" className="btn btn-top" onClick={poemsScrollTop}>Back to Top&nbsp;
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default Poems;
