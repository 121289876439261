import React from "react";
import { ScaleLoader } from "react-spinners";
import "./custom-loader.css";

const CustomLoader = ({ color = "#000" }) => {
    return (
        <div className="loader">
            <ScaleLoader className="loader" color={color} loading={true} size={100} />
        </div>
    );
}

export default CustomLoader;