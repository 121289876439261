import { addDoc, collection, serverTimestamp, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db } from "../../firebase";
import './addeditblog.css';

const initialState = {
    title: "",
}

const AddEditBlog = () => {
    const [form, setForm] = useState(initialState);
    const [file, setFile] = useState(null);
    const [wordCount, setWordCount] = useState(0);
    const [readingTime, setReadingTime] = useState(0);
    const { title } = form;
    const navigate = useNavigate();
    const storage = getStorage();

    const handleChange = (e) => {
        if (e.target.name === 'file') {
            setFile(e.target.files[0]);
            analyzeFile(e.target.files[0]);
        } else {
            setForm({ ...form, [e.target.name]: e.target.value });
        }
    }

    const analyzeFile = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result;
            const words = content.trim().split(/\s+/).length;
            const time = Math.ceil(words / 275); // Assuming 275 words per minute
            setWordCount(words);
            setReadingTime(time);
        };
        reader.readAsText(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (title && file) {
            try {
                const docRef = await addDoc(collection(db, "blogs"), {
                    title,
                    fileUrl: null,
                    wordCount,
                    readingTime,
                    createdAt: serverTimestamp(),
                    lastModified: serverTimestamp(),
                });

                const storageRef = ref(storage, `blogs/${docRef.id}.md`);
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on(
                    'state_changed',
                    (snapshot) => { },
                    (error) => {
                        console.error('Error uploading file:', error);
                        toast.error('Error uploading file');
                    },
                    async () => {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                        await updateDoc(docRef, {
                            fileUrl: downloadURL,
                            lastModified: serverTimestamp(),
                        });

                        toast.success("Blog added successfully", { autoClose: 1000 });
                        navigate("/");
                    }
                );
            } catch (error) {
                console.error('Error adding blog:', error);
                toast.error('Error adding blog');
            }
        } else {
            toast.error('Please fill out all fields and upload a markdown file');
        }
    };

    return (
        <div className="container-fluid mb-4">
            <div className="container">
                <div className="col-12">
                    <h2 className="text-center">Add a new blog</h2>
                </div>
                <div className="row h-100 justify-content-center align-items-center">
                    <div className="col-10 col-md-8 col-lg-6">
                        <form className="row blog-form" onSubmit={handleSubmit}>
                            <div className="col-12 py-3">
                                <input
                                    type="text"
                                    className="form-control input text-box"
                                    placeholder="Title"
                                    name="title"
                                    value={title}
                                    onChange={handleChange}
                                />
                                {wordCount > 0 && (
                                    <p>Word count: {wordCount} | Estimated reading time: {readingTime} min</p>
                                )}
                            </div>

                            <div className="col-12 py-3">
                                <input
                                    type="file"
                                    className="form-control input text-box"
                                    accept=".md"
                                    name="file"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-12 py-3 text-center">
                                <button
                                    type="submit"
                                    className="btn btn-add"
                                    style={{ color: "#000", border: "0.1rem solid #000" }}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddEditBlog;
