import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { React, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { db, storage } from "../../firebase";

const UpdateBlog = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [file, setFile] = useState(null);
    const [wordCount, setWordCount] = useState(0);
    const [readingTime, setReadingTime] = useState(0);

    const getBlog = async (id) => {
        const docRef = doc(db, "blogs", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            return null;
        }
    }

    useEffect(() => {
        getBlog(id).then((blog) => {
            setTitle(blog.title);
        });
    }, [id]);

    const handleChange = (e) => {
        e.preventDefault();

        if (e.target.name === "title") {
            setTitle(e.target.value);
        } else if (e.target.name === "file") {
            const selectedFile = e.target.files[0];
            setFile(selectedFile);
            analyzeFile(selectedFile);
        }
    }

    const analyzeFile = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result;
            const words = content.trim().split(/\s+/).length;
            const time = Math.ceil(words / 275); // Assuming 275 words per minute
            setWordCount(words);
            setReadingTime(time);
        };
        reader.readAsText(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await updateBlog(id, title, file);
    }

    const updateBlog = async (blogId, title, file) => {
        const blogRef = doc(db, "blogs", blogId);
        if (title && !file) {
            const updates = {
                title: title,
                lastModified: serverTimestamp(),
            };
            await updateDoc(blogRef, updates);
            toast.success('Blog updated successfully!');
            navigate('/');
            return;
        }
        else if (title && !file) {
            const updates = {
                title: title,
                lastModified: serverTimestamp(),
            };
            await updateDoc(blogRef, updates);
            toast.success('Blog updated successfully!');
            navigate('/');
            return;
        }
        else if (!title && !file) {
            const updates = {
                lastModified: serverTimestamp(),
            };
            await updateDoc(blogRef, updates);
            toast.success('Blog updated successfully!');
            navigate('/');
            return;
        }

        else {
            try {
                const storageRef = ref(storage, `blogs/${blogId}.md`);
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Optional: Track upload progress
                    },
                    (error) => {
                        console.error('Error uploading file:', error);
                        toast.error('Error uploading file');
                    },
                    async () => {
                        try {
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                            const updates = {
                                title: title,
                                fileUrl: downloadURL,
                                lastModified: serverTimestamp(),
                                readingTime: readingTime,
                                wordCount: wordCount
                            };

                            await updateDoc(blogRef, updates);
                            toast.success('Blog updated successfully!');
                            navigate('/');
                        } catch (error) {
                            console.error('Error updating blog:', error);
                            toast.error('Error updating blog');
                        }
                    }
                );
            } catch (error) {
                console.error('Error starting file upload:', error);
                toast.error('Error updating blog');
            }
        };
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="col-12 py-3">
                    <input
                        type="text"
                        value={title}
                        className="form-control input text-box"
                        placeholder="Title"
                        name="title"
                        onChange={handleChange}
                    />
                    {wordCount > 0 && (
                        <p>Word count: {wordCount} | Estimated reading time: {readingTime} min</p>
                    )}
                </div>
                <div className="col-12 py-3">
                    <input
                        type="file"
                        className="form-control input text-box"
                        accept=".md"
                        name="file"
                        onChange={handleChange}
                    />
                </div>
                <div className="col-12 py-3 text-center d-flex justify-content-center">
                    <div className="text-center">
                        <Link to="/update" className="btn btn-add" style={{ color: "#000", border: "0.1rem solid #000" }}>Go back</Link>
                    </div>
                    <button
                        type="submit"
                        className="btn btn-add"
                        style={{ color: "#000", border: "0.1rem solid #000" }}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    )
}

export default UpdateBlog;
