import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect, useRef, useState } from 'react';
import { Fade } from "react-awesome-reveal";
import BlogCard from '../../Components/BlogCard/BlogCard';
import './home.css';

const Home = (blogs) => {
    const taglineWords = ["blogs", "poems", "books"];
    const [taglineWordIndex, setTaglineWordIndex] = useState(0);
    blogs = Object.values(blogs)[0];
    blogs.sort((a, b) => b.timestamp - a.timestamp);
    const blogListRef = useRef(null);

    useEffect(() => {
        AOS.init();
        AOS.refresh();

        const interval = setInterval(() => {
            setTaglineWordIndex((prevIndex) => (prevIndex + 1) % taglineWords.length);
        }, 2400);

        return () => clearInterval(interval);
    }, [])

    const blogListScroll = () => {
        blogListRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }

    const blogScrollTop = () => {
        blogListRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }

    return (
        <div className="home">
            <section className="landing-section">
                <div className="head-text-container">
                    <div className="head-text">
                        <h1 data-aos="fade-in" data-aos-delay="250" data-aos-duration="1000" data-aos-easing="ease-in-out">Metamorph</h1>
                        <div style={{ width: "max-content" }}>
                            <div className="head-tagline" data-aos="fade-up" data-aos-delay="1150" data-aos-duration="1000" data-aos-easing="ease-in-out">
                                Home to my&nbsp;&nbsp;
                                <span className={`${taglineWordIndex === 1 ? 'tag-word-rotation show' : 'tag-word-rotation'}`}>{taglineWords[1]}</span>
                                <span className={`${taglineWordIndex === 0 ? 'tag-word-rotation show' : 'tag-word-rotation'}`}>{taglineWords[0]}</span>
                                <span className={`${taglineWordIndex === 2 ? 'tag-word-rotation show' : 'tag-word-rotation'}`}>{taglineWords[2]}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <Fade triggerOnce={true} duration={1000} delay={1800}>
                    <div className="down-arrow-container">
                        <button className="svg-button" onClick={blogListScroll}>
                            <svg id="down-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </button>
                    </div>
                </Fade>
            </section>

            <section id="all-blogs" className="all-blogs" ref={blogListRef}>
                <h2 className="blogs-header">Blog posts</h2>
                <div className="blogs-container" id="blogs-container" key={blogs.id}>
                    {blogs
                        .sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate()) // Sort by timestamp (newest first)
                        .map((blog) => (
                            <BlogCard
                                key={blog.id}
                                id={blog.id}
                                title={blog.title}
                                fileUrl={blog.fileUrl}
                                createdAt={blog.createdAt}
                                lastModified={blog.lastModified}
                                readingTime={blog.readingTime}
                            />
                        ))}
                    <div className="top-btn-container">
                        <button id="home-top-scroll" className="btn btn-top" onClick={blogScrollTop}>
                            Back to Top&nbsp;
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
