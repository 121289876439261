import figlet from "figlet";
import standard from "figlet/importable-fonts/Standard.js";
import { logEvent } from 'firebase/analytics';
import { collection, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Footer from './Components/Footer/Footer';
import NavigationBar from './Components/Navbar/Navbar';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import ScrollToTop from './Components/Scroll/ScrollToTop';
import { analytics, db } from './firebase';
import About from './Pages/About/About';
import AddEditBlog from './Pages/AddEditBlog/AddEditBlog';
import Login from './Pages/Auth/Login';
import BlogPage from './Pages/BlogPage/BlogPage';
import Books from './Pages/Books/Books';
import Home from './Pages/Home/Home';
import NotFound from './Pages/NotFound/NotFound';
import Poems from './Pages/Poems/Poems';
import UpdateBlog from './Pages/UpdateBlog/UpdateBlog';
import UpdateList from './Pages/UpdateBlog/UpdateList';

const API_KEY = process.env.REACT_APP_READING_LIST_API_KEY;
const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
const READING_LIST_RANGE = 'Reading List!A:E';
const POEMS_RANGE = 'Poems!A:B';

const App = () => {
  const [blogs, setBlogs] = useState([]);
  const [poems, setPoems] = useState([]);
  const [books, setBooks] = useState([]);

  const getBlogs = () => {
    const unsub = onSnapshot(
      collection(db, "blogs"),
      (snapshot) => {
        const list = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogs(list);
      },
      (error) => {
        console.error('Error fetching blogs:', error);
      }
    );
    return unsub;
  };

  const getReadingList = async () => {
    try {
      const response = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${READING_LIST_RANGE}?key=${API_KEY}`);
      const result = await response.json();
      setBooks(result.values);
    } catch (error) {
      console.error('Error fetching Google Sheets data:', error);
    }
  };

  const getPoems = async () => {
    try {
      const response = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${POEMS_RANGE}?key=${API_KEY}`);
      const result = await response.json();
      setPoems(result.values);
    } catch (error) {
      console.error('Error fetching poems:', error);
    }
  };

  useEffect(() => {
    logEvent(analytics, 'device_info', {
      userAgent: navigator.userAgent
    });
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        logEvent(analytics, 'location', {
          userInfo: data
        });
      })
      .catch(error => console.error('Error fetching location:', error));
    getReadingList();
    getPoems();
    figlet.parseFont("Standard", standard);

    figlet.text(
      "Metamorph",
      {
        font: "Standard",
      },
      function (err, data) {
        console.log(data);
      }
    );
    console.log("Welcome fellow 'developer'. I may or may not have used ChatGPT to build this website.")
    const unsubscribe = getBlogs();
    return () => unsubscribe();

  }, []);

  return (
    <div className="app">
      <ScrollToTop />
      <Helmet>
        <title>Metamorph</title>
        <meta property="og:title" content="Metamorph" />
        <meta property="og:description" content="Where my thoughts come alive" />
        <meta property="og:image" content="https://metamorph.blog/logo.png" />
        <meta property="og:url" content="https://metamorph.blog" />
      </Helmet>
      <div className="app-content">

        <ToastContainer position="top-center" style={{ zIndex: 10000 }} />
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home blogs={blogs} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/b/:id" element={<BlogPage blogs={blogs} />} />
          <Route path="/books" element={<Books books={books} />} />
          <Route path="/poems" element={<Poems poems={poems} />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<NotFound />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/create" element={<AddEditBlog />} />
            <Route path="/update/" element={<UpdateList blogs={blogs} />} />
            <Route path="/update/:id" element={<UpdateBlog />} />
          </Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
