import 'bootstrap/dist/css/bootstrap.css';
import React, { useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Link, NavLink, Outlet } from 'react-router-dom';
import useScrollDirection from '../Scroll/ScrollDirection';
import './navbar.css';

const NavigationBar = () => {
    const [expanded, setExpanded] = useState(false);
    const scrollDirection = useScrollDirection();

    const handleNavLinkClick = () => {
        setExpanded(false);
    };

    const themeToggle = () => {
        // Add your theme toggle logic here
    };

    return (
        <div>
            <Container className="nav-container">
                <Navbar className={`nav-bar ${scrollDirection === "down" ? "nav-hidden" : "nav-visible"}`} variant="dark" fixed="top" expand="sm" collapseOnSelect expanded={expanded} onToggle={setExpanded}>
                    <Link to="/" className="logo-link" style={{ zIndex: "0" }}>
                        <img className="logo" src={require("../Images/logo.png")} alt="Metamorph" style={{ scale: "0.8", borderRadius: "50%" }} />
                    </Link>

                    <Navbar.Toggle className="nav-toggle" aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
                    <Navbar.Collapse className="nav-collapse">
                        <Nav className="nav">
                            <NavLink className="nav-link" as={Link} to="/" onClick={handleNavLinkClick} style={({ isActive }) => ({
                                textDecoration: isActive ? "underline" : "none",
                            })}>Home</NavLink>
                            <NavLink className="nav-link" as={Link} to="/books" onClick={handleNavLinkClick} style={({ isActive }) => ({
                                textDecoration: isActive ? "underline" : "none",
                            })}>Books</NavLink>
                            <NavLink className="nav-link" as={Link} to="/poems" onClick={handleNavLinkClick} style={({ isActive }) => ({
                                textDecoration: isActive ? "underline" : "none",
                            })}>Poems</NavLink>
                            <NavLink className="nav-link" as={Link} to="/about" onClick={handleNavLinkClick} style={({ isActive }) => ({
                                textDecoration: isActive ? "underline" : "none",
                            })}>About</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                    <div className="nav-theme-toggle">
                        <button className="toggle-btn" onClick={themeToggle}>
                            <svg id="toggle-on" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                                <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                            </svg>
                            <svg id="toggle-off" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-moon-fill" viewBox="0 0 16 16">
                                <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z" />
                            </svg>
                        </button>
                    </div>
                </Navbar>
            </Container>
            <Outlet />
        </div >
    );
}

export default NavigationBar;
