import React from 'react';
import { Link } from 'react-router-dom';

const UpdateList = ({ blogs }) => {
    return (
        <div style={{ marginLeft: '4rem' }}>
            <h1>All Blogs</h1>
            {blogs.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate()) // Sort by timestamp (newest first)
                .map((blog) => (
                    <div key={blog.id}>
                        <Link to={`/update/${blog.id}`}>{blog.title}</Link>
                    </div>
                ))}
        </div>
    )
}

export default UpdateList;
